<template>
  <div class="user-manager-page" v-loading="!state.loaded">
    <div class="title">用户管理</div>
    <div class="filter-wrapper" v-if="state.list.length">
      <el-button type="primary" round size="mini" :loading="state.exporting" @click="exportData">
        {{ state.exporting ? '导出中...' : '导出数据' }}
      </el-button>
    </div>
    <div class="table-wrapper">
      <template v-if="!state.list.length && state.loaded">
        <empty-page description="暂无用户信息"></empty-page>
      </template>
      <template v-else>
        <el-table
          cell-class-name="custom-table-cell"
          header-row-class-name="custom-table-header"
          :data="state.list"
          v-loading="state.loading"
          @select="handleSelect"
          @select-all="handleSelectAll"
          empty-text="暂无数据"
          style="width: 100%"
          :row-class-name="tableRowClassName"
        >
          <el-table-column align="center" type="selection" width="45"></el-table-column>
          <el-table-column
            align="left"
            header-align="left"
            show-overflow-tooltip
            prop="login_name"
            label="账号"
            width="120"
          ></el-table-column>
          <el-table-column align="left" header-align="left" show-overflow-tooltip prop="name" label="姓名" width="120">
            <template #default="scope">
              <wxview type="userName" :openid="scope.row.name">{{ scope.row.name }}</wxview>
            </template>
          </el-table-column>
          <el-table-column align="left" header-align="left" prop="mobile" label="手机号" width="120"></el-table-column>
          <el-table-column
            align="left"
            header-align="left"
            prop="createTime"
            :formatter="timeformatter"
            label="添加时间"
          ></el-table-column>
          <el-table-column align="left" header-align="left" prop="status" label="状态" width="80">
            <template #default="scope">
              <el-switch
                v-model="scope.row.status.id"
                :disabled="scope.row.roles[0] === '5' || scope.row.id === state.uuid"
                :data-id="scope.row.id"
                :data-uuid="state.uuid"
                :data-disabeld="scope.row.id === state.uuid"
                active-color="#13ce66"
                active-value="1"
                inactive-value="2"
                @change="updateStatus(scope.row)"
              ></el-switch>
            </template>
          </el-table-column>
          <!-- <el-table-column align="left" header-align="left" prop="mobile" label="操作" width="90">
            <template #default="scope">
              <template v-if="scope.row.status.id !== '1'">
                <span class="edit option" @click="onEdit(scope.row)">编辑</span>
                <span class="delete option" @click="onDelete(scope.row)">删除</span>
              </template>
            </template>
          </el-table-column> -->
        </el-table>
      </template>
    </div>
    <pagination-block
      v-if="state.list?.length"
      @page-change="handleCurrentChange"
      v-model="state.pageNo"
      :page-size="state.pageSize"
      @size-change="handleSizeChange"
      :total="state.count"
    ></pagination-block>
  </div>
  <edit-drawer @success="success" :id="state.editId" v-model:show="state.showEdit"></edit-drawer>
</template>
<script>
import { reactive } from 'vue'
import EmptyPage from '@/views/components/empty-page'
import { deleteUser, exportUser, getUserList, updateUserStatus } from '@/apis'
import { timeFormat } from '@/common/utils/format'
import EditDrawer from './edit-drawer'
import { useDeleteConfirm } from '@/hooks/ui'
import PaginationBlock from '@/components/pagination'
import { useUser } from '@/hooks'
export default {
  name: 'userManager',
  components: { EmptyPage, EditDrawer, PaginationBlock },
  setup() {
    const timeformatter = row => {
      return timeFormat(row.createTime, 'YYYY-MM-DD HH:mm')
    }
    const {
      state,
      onDelete,
      onEdit,
      updateStatus,
      success,
      handleSizeChange,
      handleCurrentChange,
      exportData,
      handleSelectAll,
      handleSelect
    } = useUserManager()
    return {
      state,
      timeformatter,
      onEdit,
      onDelete,
      updateStatus,
      success,
      handleSizeChange,
      handleCurrentChange,
      exportData,
      handleSelectAll,
      handleSelect
    }
  }
}
function useUserManager() {
  const state = reactive({
    list: [],
    loaded: false,
    pageNo: 1,
    pageSize: 10,
    count: 0,
    showEdit: false,
    editId: '',
    exporting: false,
    selectList: [],
    uuid: JSON.parse(localStorage.getItem('kht-user-info'))?.uuid
  })
  const deleteConfirm = useDeleteConfirm()
  const { user } = useUser()
  console.log('user: ', user.uuid)
  const initUserList = async params => {
    try {
      const query = { corpNo: user.value.companyNo, pageSize: state.pageSize, pageNo: state.pageNo, ...params }
      const { data } = await getUserList(query)
      console.log('result', data)
      state.list = data?.list || []
      state.count = data?.count || 0
    } catch (error) {
      console.log('initUserList error', error)
    } finally {
      state.loaded = true
    }
  }
  const onEdit = row => {
    console.log('row', row.id)
    state.editId = row.id
    state.showEdit = true
  }
  const onDelete = async row => {
    if (await deleteConfirm('您确定删除该用户吗?')) {
      await deleteUser(row.id)
      initUserList()
    }
  }
  const updateStatus = async row => {
    try {
      // 注意 这里得到的状态是改变之后的
      const status = row.status.id !== '1' ? '2' : '1'
      await updateUserStatus({ id: row.id, status })
      return true
    } catch (error) {
      console.log('updateStatus error', error)
      return Promise.reject(false)
    }
  }
  const success = () => {
    initUserList()
  }
  const handleCurrentChange = page => {
    state.pageNo = page
    initUserList()
  }
  const handleSizeChange = pageSize => {
    state.pageNo = 1
    state.pageSize = pageSize
    initUserList()
  }
  const handleSelect = selection => {
    state.selectList = selection
  }
  const handleSelectAll = selection => {
    state.selectList = selection
  }
  const tableRowClassName = ({ row, rowIndex }) => {
    console.log('rowIndex: ', rowIndex)
    console.log('row: ', row)
    if (rowIndex === 1) {
      return 'warning-row'
    } else if (rowIndex === 3) {
      return 'success-row'
    }
    return ''
  }
  initUserList()
  const exportData = async () => {
    try {
      state.exporting = true
      const ids = state.selectList.map(item => item.id)
      const params = {
        corpNo: user.value.companyNo,
        id: ids
      }
      await exportUser(params)
    } catch (error) {
      console.log('export Data error', error)
    } finally {
      state.exporting = false
    }
  }
  return {
    state,
    onEdit,
    onDelete,
    updateStatus,
    success,
    handleSizeChange,
    handleCurrentChange,
    exportData,
    handleSelect,
    handleSelectAll,
    tableRowClassName
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
