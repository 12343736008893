<template>
  <el-drawer
    custom-class="user-drawer"
    destroy-on-close
    :show-close="false"
    :modal="false"
    v-model="showDrawer"
    direction="rtl"
    size="599px"
  >
    <template #title>
      <div class="my-title">
        <span>编辑用户</span>
        <span class="id">(ID: {{ id }})</span>
      </div>
    </template>
    <div class="user-edit-drawer-wrapper">
      <div class="user-container">
        <el-form :model="form" ref="formRef" :rules="rules" size="small" label-position="right" label-width="152px">
          <el-form-item prop="account" label="" label-width="0">
            <div class="avatar-wrap">
              <el-upload
                class="avatar-uploader"
                action="/"
                :accept="settings.accept"
                :before-upload="beforeUpload"
                :http-request="httpRequest"
                :show-file-list="false"
              >
                <div class="avatar-uploader-icon">
                  <userAvatar :url="form.profileUrl"></userAvatar>
                </div>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item prop="account" label="姓名&nbsp;:">
            <el-input size="small" :disabled="true" v-model="form.username"></el-input>
          </el-form-item>
          <el-form-item prop="account" label="账号&nbsp;:">
            <el-input size="small" :disabled="true" v-model="form.loginName"></el-input>
          </el-form-item>
          <el-form-item prop="account" label="企业微信&nbsp;:">
            <el-input size="small" :disabled="true" v-model="form.userId"></el-input>
          </el-form-item>
          <el-form-item label="手机号&nbsp;:" prop="mobile">
            <el-input size="small" v-number v-model="form.mobile"></el-input>
          </el-form-item>
          <el-form-item label="生日&nbsp;:">
            <el-date-picker
              v-model="form.birthday"
              format="YYYY-MM-DD"
              type="date"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="性别&nbsp;:">
            <span>{{ form.gender?.desc }}</span>
            <i
              v-if="form.gender?.id"
              class="iconfont grander-icon"
              :class="[form.gender?.id === '1' ? 'icon-nan man' : 'icon-nv woman']"
            ></i>
          </el-form-item>
          <el-form-item label="城市&nbsp;:">
            <el-cascader
              :options="cityData"
              v-model="form.areaIds"
              separator=""
              popper-class="city-select-cascader"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="个性签名&nbsp;:">
            <el-input size="small" v-model="form.sign"></el-input>
          </el-form-item>
          <el-form-item label="微信&nbsp;:">
            <el-input size="small" v-model="form.weChat"></el-input>
          </el-form-item>
          <el-form-item label="邮箱&nbsp;:" prop="email">
            <el-input size="small" v-model="form.email"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="footer">
        <el-button type="primary" @click="saveClick">保存</el-button>
        <el-button type="danger" plain @click="handleDelete">删除</el-button>
        <el-button type="default" @click="showDrawer = false">取消</el-button>
      </div>
    </div>
  </el-drawer>
</template>
<script>
import { computed, onUnmounted, reactive, ref, watchEffect } from 'vue'
import { useFileUpload } from '@/hooks/upload'
import { timeFormat } from '@/common/utils/format'
import { emailValidator, mobildValidator } from '@/common/validator'
import { useArea } from '@/hooks/area'
import { useInitUserInfo } from '@/views/user/info/index.vue'
import { deleteUser, updateUserInfo } from '@/apis'
import { ElMessage } from 'element-plus'
import { useDeleteConfirm } from '@/hooks/ui'
export default {
  name: 'editDrawer',
  props: {
    show: {
      type: Boolean,
      default: true
    },
    id: {
      type: String,
      default: ''
    }
  },
  emits: ['update:show', 'success'],
  setup(props, { emit }) {
    const showDrawer = computed({
      get() {
        return props.show
      },
      set(bool) {
        emit('update:show', bool)
      }
    })
    const loading = ref(false)
    const formRef = ref(null)
    const form = reactive({
      profileUrl: '', // 头像
      profileId: '',
      id: '', // Id
      loginName: '', // 账号
      userId: '', // 企业微信Id
      mobile: '', // 手机号码
      birthday: '', // 生日
      gender: {}, // 性别
      email: '', // 邮箱
      weChat: '', // 微信
      areaIds: [],
      sign: '' // 个性签名
    })
    const rules = {
      mobile: [
        { required: true, message: '请输入手机号码', trigger: ['blur', 'change'] },
        { validator: mobildValidator, message: '手机号码格式有误', trigger: ['blur', 'change'] }
      ],
      email: {
        validator: emailValidator,
        message: '邮箱格式错误',
        trigger: ['blur', 'change']
      }
    }
    const { getProvinceAndCityData, getNameByCodes } = useArea()
    const cityData = computed(() => {
      return getProvinceAndCityData()
    })
    // 头像上传相关
    const { settings, beforeUpload, httpRequest } = useFileUpload(
      file => {
        form.profileId = file.id
        form.profileUrl = file.id
      },
      { accept: '.png,.jpg', limit: 1, size: '10MB' }
    )
    const handleRequeset = async params => {
      try {
        await updateUserInfo(params)
        ElMessage.success('保存成功')
        showDrawer.value = false
        emit('success')
        useInitUserInfo(props.id, form)
      } catch (error) {
        console.log('handleRequeset', error)
      } finally {
        loading.value = false
      }
    }
    const saveClick = () => {
      formRef.value.validate(valid => {
        if (!valid) return false
        loading.value = true
        const params = {
          id: form.id,
          avatar: form.profileId,
          login_name: form.loginName,
          weChat: form.weChat,
          mobile: form.mobile,
          email: form.email,
          areaIds: form.areaIds,
          areaNames: getNameByCodes(form.areaIds),
          sign: form.sign,
          birthday: timeFormat(form.birthday, 'YYYY-MM-DD')
        }
        handleRequeset(params)
      })
    }
    const deleteConfirm = useDeleteConfirm()
    const handleDelete = async () => {
      if (await deleteConfirm('您确定删除该用户吗?')) {
        await deleteUser(props.id)
        emit('success')
        showDrawer.value = false
      }
    }
    const stop = watchEffect(() => {
      console.log('useInitUserInfo', props.id)
      if (props.id) {
        useInitUserInfo(props.id, form)
      }
    }, [props.id])
    onUnmounted(() => {
      typeof stop === 'function' && stop()
    })
    return {
      showDrawer,
      formRef,
      form,
      rules,
      settings,
      httpRequest,
      beforeUpload,
      cityData,
      saveClick,
      handleDelete
    }
  }
}
</script>
<style lang="scss">
@import './index.scss';
</style>
